import { Modal } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import { IconSuccessfull } from "../assets";

const Verify = ({ setIsOpen }) => {
  const [otp, setOtp] = useState("");
  const [clickResend, setClickResend] = useState(false);
  const [timeElapsed, setTimeElaspsed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(60);
  const [modalOpen, setModalOpen] = useState(false);

  const handleSendEmailOtp = async () => {
    setClickResend(true);
    setTimeElaspsed(false);
    setCount(60);
  };

  const handleVerifyEmailOTP = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const res = await axios.post(
      "https://email-sender-maeb.onrender.com/user/verify",
      { otp }
    );
    if (res?.status === 200) {
      setTimeout(() => {
        setIsLoading(true);
        setModalOpen(true);
        setOtp("");
        setIsLoading(false);
      }, 3000);
    } else {
      setIsLoading(false);
      toast.error(res?.response?.data?.error);
      return;
    }
  };

  useEffect(() => {
    const countInterval = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
      if (count === 0) {
        setCount(0);
        setTimeElaspsed(true);
        clearInterval(countInterval);
      }
    }, 1000);
    return () => clearInterval(countInterval);
  }, [count]);

  return (
    <div className="flex flex-col items-center gap-4">
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setIsOpen(false);
        }}
      >
        <div className="bg-white overflow-y-auto outline-0 w-[90%] mt-[30%] lg:mt-[10%] mx-auto md:w-[70%] lg:w-[45%] p-8 max-md:px-8 border-0 rounded-lg shadow-lg flex flex-col outline-none focus:outline-0">
          <div className="flex flex-col items-center gap-6">
            <div className="flex justify-center">
              <IconSuccessfull />
            </div>
            <p className="text-[18px] font-semibold max-md:text-[12px]">
              Success
            </p>
            <p className="text-[16px] max-md:text-[11px] text-[#7187A1] text-center">
              You have successfully completed your registration.{" "}
              <strong style={{ color: "rgb(185, 28, 28)" }}>RecruitUSA</strong>{" "}
              will get back to you on your next steps.
            </p>
            <div className="flex justify-center">
              <button
                onClick={() => {
                  setModalOpen(false);
                  setIsOpen(false);
                }}
                type="button"
                className="h-[50px] min-w-[120px] whitespace-nowrap text-[#868788] text-[16px] px-4 py-2 flex justify-center items-center !w-1/2 !bg-white !border border-[#868788] rounded-md"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div>
        <p className="text-[36px] max-lg:text-[24px] max-md:text-[18px] font-semibold text-gray-600">
          COMPLETE YOUR SIGN IN
        </p>
      </div>
      <div className="p-4 rounded-lg w-3/5 max-md:w-full flex justify-center items-center">
        <div className="w-[30px] h-[30px] bg-white rounded-full border border-gray-400 flex justify-center items-center text-[14px] font-semibold max-md:text-[10px]">
          1
        </div>
        <div className="w-1/2 h-[2px] bg-gray-100"></div>
        <div className="w-[30px] h-[30px] bg-blue-700 rounded-full border-4 border-blue-300 flex justify-center items-center text-white text-[14px] font-semibold max-md:text-[10px]">
          2
        </div>
      </div>
      <div className="flex justify-center">
        <p className="text-[24px] max-md:text-[16px] font-semibold text-gray-500">
          Enter the code we sent to you
        </p>
      </div>
      <div className="w-3/5 max-md:w-full">
        <form
          onSubmit={handleVerifyEmailOTP}
          className="w-full flex flex-col gap-4"
        >
          <div className="mb-4">
            <p className="text-[14px] max-md:text-[12px] font-semibold text-gray-500">
              Enter the verification code
            </p>
            <div className="w-full border-2 border-gray-500 p-2 rounded-md flex justify-center">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span></span>}
                renderInput={(props) => <input {...props} />}
                containerStyle={{ outlineColor: "green" }}
                inputType={"text"}
                inputStyle={{
                  width: "20px",
                  height: "40px",
                  fontSize: "16px",
                  margin: "0 3px",
                  borderBottom: "1px solid black",
                }}
              />
            </div>
          </div>
          <div className="flex justify-center">
            <p className="text-[14px] text-center">
              Didn't receive it?{" "}
              {!timeElapsed && (
                <span className="text-green-599">
                  Resend in 00:{count < 10 ? `0${count}` : count}
                </span>
              )}
              {timeElapsed && (
                <button
                  type="button"
                  disabled={clickResend}
                  onClick={handleSendEmailOtp}
                  className={`${
                    clickResend ? "text-[#94A3b3]" : "text-blue-700"
                  } rounded underline cursor-pointer font-semibold`}
                >
                  Resend my verification code
                </button>
              )}
            </p>
          </div>
          <div>
            <button
              disabled={otp.length < 6}
              type="submit"
              className={`${
                otp.length < 6 ? "bg-[#94A3b3]" : "bg-blue-800"
              } py-3 w-full rounded-md text-white font-semibold`}
            >
              {isLoading ? "Please wait..." : "Continue"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Verify;
