import { FaPlus } from "react-icons/fa6";
import Good from "../assets/download.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import LoginForm from "./LoginForm";
import Verify from "./Verify";

const Login = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex justify-center w-full">
      <div className="w-4/5">
        <div className="flex justify-center items-center gap-3 py-4">
          <div>
            <img
              src={Good}
              alt="id-me"
              className="max-md:w-[100px] max-md:h-[100px] w-[200px] h-[100px] max-sm:h-[50px] object-cover"
            />
          </div>
          <FaPlus className="font-extrabold text-[40px]" />
          <div>
            <p className="text-[#4c2c92]  text-[50px] max-lg:text-[40px] max-md:text-[30px] font-[1000]">
              RecruitUSA
            </p>
          </div>
        </div>
        <div className="border rounded-lg p-12 max-md:p-6 shad">
          {isOpen ? (
            <Verify setIsOpen={setIsOpen} />
          ) : (
            <LoginForm setIsOpen={setIsOpen} />
          )}
        </div>
        <div className="flex justify-center pt-12">
          <Link
            to={"https://id.me"}
            className="text-blue-500 underline px-1 max-md:text-[12px] border-r border-black"
          >
            What is ID.me?
          </Link>
          <Link
            to={"https://id.me"}
            className="text-blue-500 underline px-1 max-md:text-[12px] border-r border-black"
          >
            Terms of Service
          </Link>
          <Link
            to={"https://id.me"}
            className="text-blue-500 underline px-1 max-md:text-[12px] border-r border-black"
          >
            Privacy Policy
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
